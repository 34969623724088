export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('v_120924_2');
    // Mobile menu toggle
    const mobileToggle = document.querySelector('.mobile-toggle');
    const mobileMenu = document.querySelector('.mobile-menu');
    if (mobileToggle) {
      mobileToggle.addEventListener('click', () => {
        mobileToggle.classList.toggle('open');
        document.documentElement.classList.toggle('no-scroll');
        const mainHeader = document.querySelector('.main-header');
        if (mainHeader) {
          mainHeader.classList.toggle('open');
        }
        if (mobileMenu) {
          mobileMenu.classList.toggle('open');
        }
      });
    }

    // Handle hover for desktop navigation
    const navItems = document.querySelectorAll('.nav > .menu-item-has-children');
    navItems.forEach(item => {
      item.addEventListener('mouseenter', () => {
        navItems.forEach(navItem => navItem.classList.remove('open'));
        item.classList.add('open');
      });
    });
    document.querySelectorAll('.sub-menu').forEach(subMenu => {
      subMenu.addEventListener('mouseleave', () => {
        navItems.forEach(navItem => navItem.classList.remove('open'));
      });
    });

    // Welcome banner
    const welcomeBanner = document.querySelector('.welcome-banner');
    if (welcomeBanner) {
      const bannerStatus = localStorage.getItem('welcomeBanner');
      if (bannerStatus) {
        welcomeBanner.classList.add('hide');
      } else {
        const bannerLink = welcomeBanner.querySelector('a');
        if (bannerLink) {
          bannerLink.addEventListener('click', (e) => {
            e.preventDefault();
            welcomeBanner.classList.add('hide');
            localStorage.setItem('welcomeBanner', 'shown');
          });
        }
      }
    }

    // Video functionality
    const popUpVideo = document.querySelector('.popup-video-container');
    const player = popUpVideo ? popUpVideo.querySelector('iframe') : null;
    const videoTriggers = document.querySelectorAll('.video-container[data-video], .video-carousel-slide[data-video]');
    const spotlightVideoTrigger = document.querySelector('.spotlight-video-trigger');

    if (popUpVideo && player) {
      // Video triggers
      videoTriggers.forEach(videoTrigger => {
        videoTrigger.addEventListener('click', () => {
          const videoUrl = videoTrigger.dataset.video;
          if (videoUrl && player.src !== videoUrl) {
            player.classList.add('fade-in');
            player.src = videoUrl;
          }
          popUpVideo.classList.add('show');
        });
      });

      // Spotlight video
      if (spotlightVideoTrigger) {
        spotlightVideoTrigger.addEventListener('click', () => {
          const videoUrl = spotlightVideoTrigger.dataset.video;
          if (videoUrl && player.src !== videoUrl) {
            player.classList.add('fade-in');
            player.src = videoUrl;
          }
          popUpVideo.classList.add('show');
        });
      }

      // Popup close actions
      const closePopUp = () => {
        popUpVideo.classList.remove('show');
        player.src = ''; // Reset video playback
      };
      popUpVideo.addEventListener('click', closePopUp);
      const closeButton = popUpVideo.querySelector('svg');
      if (closeButton) {
        closeButton.addEventListener('click', closePopUp);
      }
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          closePopUp();
        }
      });
    } else {
      console.error('Popup video container or iframe player is missing.');
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page-specific JS is fired
  },
};